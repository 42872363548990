import type { PageContext } from "vike/types";

export function localeAware(pageContext: PageContext) {
  const locales = ["en", "de"];
  return {
    withLocale: (url: string): `/${string}` => {
      if (locales.some((l) => url.startsWith(`/${l}`))) {
        return url as `/${string}`;
      }
      return `/${pageContext.locale}${url}`;
    },
  };
}
