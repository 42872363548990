import { redirect } from "vike/abort";
import { modifyUrl } from "vike/modifyUrl";
import type { OnBeforeRouteSync, Url } from "vike/types";

export { onBeforeRoute };

const onBeforeRoute: OnBeforeRouteSync = (pageContext) => {
  const { urlWithoutLocale, locale } = extractLocale(pageContext.urlParsed);

  // if there's no locale, redirect to either user preference or /de
  if (!["en", "de"].includes(locale)) {
    const localeCookie = useCookies(null, pageContext).get("locale");
    const locale = pageContext.user?.preferred_language ?? localeCookie ?? "de";
    // strip trailing / from urlOriginal
    const path = pageContext.urlPathname.replace(/\/$/, "");

    // use new path with locale, keeping query params
    let redirectionUrl = `/${locale}${path}`;

    if (pageContext.urlParsed.searchOriginal) {
      redirectionUrl += pageContext.urlParsed.searchOriginal;
    }

    throw redirect(redirectionUrl);
  }

  return {
    pageContext: {
      // Make `locale` available as `pageContext.locale`
      locale: locale as "en" | "de",
      // Vike's router will use pageContext.urlLogical instead of pageContext.urlOriginal
      urlLogical: urlWithoutLocale || "/",
    },
  };
};

function extractLocale(url: Url) {
  const { pathname } = url;

  const locale = pathname.split("/")[1];
  const pathnameWithoutLocale = pathname.replace(`/${locale}`, "");

  const urlWithoutLocale = modifyUrl(url.href, {
    pathname: pathnameWithoutLocale,
  });

  return { locale, urlWithoutLocale };
}
